import {Link} from '@remix-run/react';
import {useRef, useState, useEffect} from 'react';
import {IconChevronRight} from '~/components/IconChevronRight';
import {Trans} from '~/components/Trans';

export function ActionButton({className}: {className?: string}) {
  const ref = useRef<HTMLDivElement>(null);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    if (ref.current) {
      if (hover) {
        ref.current.style.opacity = '1';
      } else {
        ref.current.style.opacity = '0';
      }
    }
  }, [hover]);

  return (
    <Link
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      role="button"
      to="?cotiza-tu-proyecto=true"
      className={`flex flex-col justify-center items-center border-2 border-white rounded-full px-6 py-3 relative ${
        className ?? ''
      }`}
    >
      <div
        ref={ref}
        className="absolute rounded-full inset-[-3px]"
        style={{
          opacity: 0,
          transition: 'opacity 0.5s',
          background:
            'linear-gradient(135.36deg, #424CD5 9.71%, #4452D3 9.72%, #25A4F8 100%)',
        }}
      />

      <span className="z-10 text-sm font-semibold tracking-widest leading-relaxed text-center text-white uppercase">
        <Trans i18nKey="pages:inicio:hero.action.0" />
      </span>

      <span className="flex z-10 items-center">
        <span className="text-xs text-white">
          <Trans i18nKey="pages:inicio:hero.action.1" />
        </span>

        <IconChevronRight className="fill-white" />
      </span>
    </Link>
  );
}
