import {Trans} from '~/components/Trans';
import {Splide, SplideSlide} from '@splidejs/react-splide';
import {AutoScroll} from '@splidejs/splide-extension-auto-scroll';
import {ImageType} from '~/features/shared';

export function Clients({clientes}: {clientes: ImageType[]}) {
  return (
    <section className="flex flex-col gap-10 items-center py-20">
      <h1 className="text-4xl font-thin text-center uppercase sm:text-5xl md:text-7xl lg:text-8xl text-blue-dark">
        <Trans i18nKey="pages:inicio:clients.title" />
      </h1>

      <div className="w-full">
        <Splide
          options={{
            fixedWidth: 150,
            fixedHeight: 150,
            type: 'loop',
            drag: 'free',
            focus: 'center',
            autoScroll: {
              speed: 1,
              pauseOnHover: false,
              pauseOnFocus: false,
            },
            gap: '2rem',
            // lazyLoad: 'nearby',
            pagination: false,
            // preloadPages: 2,
            perPage: 5,
          }}
          extensions={{
            AutoScroll,
          }}
        >
          {clientes.map((cliente) => {
            return (
              <SplideSlide key={cliente.image.url}>
                <img
                  style={{
                    width: 150,
                    height: 150,
                  }}
                  src={cliente.image.url}
                  alt={cliente.image.altText}
                  width={150}
                  height={150}
                  className="object-contain"
                />
              </SplideSlide>
            );
          })}
        </Splide>
      </div>

      {/* <LinkButton className="px-10">
        <Trans i18nKey="pages:inicio:clients.action" />
      </LinkButton> */}
    </section>
  );
}
