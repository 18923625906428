import {twMerge} from 'tailwind-merge';

export function Title({
  children,
  isHtmlTitle,
}: {
  children: React.ReactNode;
  isHtmlTitle?: boolean;
}) {
  const leading = 'sm:leading-normal md:leading-normal lg:leading-normal';
  const size = 'text-4xl md:text-4xl lg:text-5xl';

  if (isHtmlTitle) {
    return (
      <h1
        className={twMerge(
          'text-white uppercase text-center z-0 font-light',
          leading,
          size
        )}
        dangerouslySetInnerHTML={{
          __html: children as string,
        }}
      />
    );
  }

  return (
    <h1
      className={twMerge(
        'text-white uppercase text-center z-0 font-light',
        leading,
        size
      )}
    >
      {children}
    </h1>
  );
}
