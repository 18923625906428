import {VideoType} from '~/features/shared';
import {ActionButton} from './ActionButton';
import {Title} from './Title';

export interface HeroProps {
  video?: VideoType;
  title: React.ReactNode;
  isHtmlTitle?: boolean;
}

export function Hero({video, title, isHtmlTitle}: HeroProps) {
  const poster = video?.previewImage;
  const sources = video?.sources ?? [];
  const source = sources.find((s) => s.format === 'mp4');

  return (
    <section className="flex relative flex-col justify-center items-center px-10 h-screen bg-black">
      <div className="absolute inset-0">
        {source ? (
          <video
            onContextMenu={(e) => e.preventDefault()}
            controlsList="nodownload"
            autoPlay
            muted
            loop
            playsInline
            className="object-cover w-full h-full lg:object-fill"
            poster={poster ? poster.url : undefined}
          >
            <source src={source.url} type={source.mimeType} />
          </video>
        ) : null}
      </div>

      <div className="absolute inset-0 bg-black bg-opacity-40" />

      <Title isHtmlTitle={isHtmlTitle}>{title}</Title>

      <ActionButton className="flex mt-20 lg:hidden" />

      <div className="hidden absolute inset-x-0 bottom-16 justify-center lg:flex">
        <ActionButton />
      </div>
    </section>
  );
}
